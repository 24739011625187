import React from 'react'
import Layout from '../../components/layout'
import { StaticQuery, graphql } from 'gatsby'

import Nav from '../../components/cn/nav'

const LearnPage = () => (
  <StaticQuery
    query={graphql`
      query CNLearnQuery {
        wordpressPage(wordpress_id: { eq: 191 }) {
          acf {
            chinese
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Nav />
        <div className="app-main body-main">
          <h1>想要更了更多？</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: data.wordpressPage.acf.chinese,
            }}
          />
        </div>
      </Layout>
    )}
  />
)

export default LearnPage
